<template>
  <div>
    <div class="header">
      <van-nav-bar
        :title="$t('webnames')"
        left-arrow
        @click-left="$router.go(-1)"
      />
    </div>
    <!--    <div class="maincontent">
          <van-row class="total">
            <van-row class="head">
              <van-image style="100%" :src="news.image" />
            </van-row>
          </van-row> -->
    <van-row class="agreement">
      <p class="text" style='padding: 65px 20px 0;text-align: justify;' v-html="$t('descsss')"></p>
      <p class="text" style='padding: 20px 20px 0;text-align: justify;' v-html="$t('descsss2')"></p>
    </van-row>
    <!-- </div> -->
  </div>
</template>
<script>
export default {
  data() {
    return {
      news: {
      }
    }
  },
  created() {
    // this.getnews()
  },
  methods: {
    // 获取新闻信息
    // async getnews() {
    //   const { data } = await this.$http.get(
    //     '/home/news/newsdetail/id/' + this.$route.params.id
    //   )
    //   if (data) {
    //     if (data.code === 200) {
    //       this.news = data.data
    //     } else {
    //       this.$toast.fail(this.$t('common.fail'))
    //     }
    //   }
    // }
  }
}
</script>
<style lang="less" scoped>
.maincontent {
  padding-top: 50px;
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  font-size: 0.93333rem;
  background: #f7f7f7;
  height: 100vh;
  .total {
    width: 100%;
    display: flex;
    justify-content: center;
    .head {
      width: 94%;
      color: #000;
      line-height: 1.86667rem;
      text-align: left;
    }
  }
  .agreement {
    width: 100%;
    color: #000;
    overflow: hidden;
    p {
      text-align: left;
      margin: 0.66667rem;
      font-size: 0.93333rem;
      word-wrap: break-word;
      overflow: hidden;
      line-height: 1.3rem;
    }
  }

}
</style>
